import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link', 'secondaryMenu', 'userMenu', 'userLink', 'mainMenu', 'burger']

  showMainMenu() {
    if (this.burgerTarget.classList.contains('active')) {
      // Hide all secondary menu's
      this.secondaryMenuTargets.forEach((menu) => {
        menu.style.display = 'none'
      })
    }
    this.mainMenuTarget.classList.toggle('tw-hidden')
    this.burgerTarget.classList.toggle('active')
  }

  showSecondaryMenu(event) {
    event.preventDefault()

    // Hide all secondary menu's
    this.secondaryMenuTargets.forEach((menu) => {
      menu.style.display = 'none'
    })

    // Remove active class from all menus
    this.linkTargets.forEach((link) => {
      link.classList.remove('active')
    })

    // Show the clicked secondary menu
    const clickedLink = event.currentTarget
    const menuId = clickedLink.dataset.menuId
    const targetSecondaryMenu = this.secondaryMenuTargets.find((menu) =>
      menu.dataset.menuId === menuId,
    )

    // Add active class to clicked menu and show secondary menu
    clickedLink.classList.add('active')
    targetSecondaryMenu.style.display = 'block'
  }

  closeSecondaryMenu() {
    // Hide all secondary menu's
    this.secondaryMenuTargets.forEach((menu) => {
      menu.style.display = 'none'
    })
  }

  showUserMenu(event) {
    event.preventDefault()
    this.userMenuTarget.classList.toggle('tw-hidden')
  }
}
